<template>
  <div ref="viewReceiptManage" class="content-wrapper view-receipt-manage pt-3">
    <div class="content">
      <b-card>
        <h4><i class="fas fa-receipt"></i> จัดการใบเสร็จ</h4>
        <div class="row mx-1 my-3">
          <b-col class="px-2" cols="12" sm="4">
            <date-picker
              class="mb-1"
              v-model="dateRange"
              type="date"
              range
              format="DD MMM YY"
              :shortcuts="dateRangeShortcuts"
              :disabled-date="notAfterToday"
              placeholder="เลือกวันที่ต้องการค้นหา"
              input-class="form-control"
              @input="handleDateChange"
            />
            <div v-if="isDateSelected" class="description text-danger">
              กรุณาเลือกวันที่
            </div>
          </b-col>

          <b-col class="px-2" cols="12" sm="4">
            <b-form-input
              type="search"
              class="col mb-1"
              autofocus
              v-model.trim="searchTable"
              placeholder="ค้นหาจาก เลขที่ DN หรือ ชื่อคนไข้"
              @change="debouncedSearch"
            />
          </b-col>

          <b-col class="px-2" cols="12" sm="4">
            <b-form-group
              class="mb-2"
              label="สถานะใบเสร็จ:"
              label-cols="5"
              label-align="right"
            >
              <b-form-select
                v-model="receiptStatus"
                :options="receiptStatusOpt"
                @input="handleStatusChange"
              />
            </b-form-group>
          </b-col>
        </div>

        <b-table
          id="receiptManageTable"
          ref="receiptManageTable"
          hover
          small
          sort-icon-left
          sort-by="id"
          :sort-desc="true"
          head-variant="light"
          :fields="fields"
          :items="receiptList"
          responsive
        >
          <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
          <template v-slot:cell(creationDt)="row">{{
            formatDate(row.value)
          }}</template>
          <template #cell(dn)="row">
            <span v-if="row.item.patient" class="narrow-spacing">
              {{ row.item.patient.dn }}
              <span
                v-if="row.item.patient.existDn"
                class="small-text text-muted"
              >
                /{{ row.item.patient.existDn }}
              </span>
            </span>
          </template>
          <template v-slot:cell(firstNameTh)="row">
            <template v-if="row.item.patient">
              {{ row.item.patient.firstNameTh }}
              {{ row.item.patient.lastNameTh }}
            </template>
          </template>
          <template v-slot:cell(button)="row">
            <b-button
              class="show-when-hovered"
              size="xs"
              @click="openReceiptModal(row.item)"
            >
              <i class="fas fa-info"></i>
            </b-button>
          </template>
          <template v-slot:cell(status)="row">
            <span
              v-if="row.item.cancel"
              v-b-popover.hover.bottomleft="cancelPopoverConfig(row.item)"
            >
              <i class="fas fa-ban text-danger"></i>
            </span>
          </template>
          <template v-slot:custom-foot v-if="isTableLoading">
            <b-tr>
              <b-td colspan="12" class="text-center mt-2">
                <b-spinner variant="primary"></b-spinner>
              </b-td>
            </b-tr>
          </template>
        </b-table>
      </b-card>
      <PaymentReceiptPreviewModal ref="paymentReceiptPreviewModal" />
      <Dialog ref="Dialog" />
      <Loading v-if="isLoading" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import PaymentReceiptPreviewModal from "@/components/modal/PaymentReceiptPreviewModal";
import moment from "moment";
import debounce from "@/utils/debounce";
import { eventBus } from "../main";

export default {
  name: "ReceiptManage",
  components: {
    Dialog,
    Loading,
    PaymentReceiptPreviewModal,
  },

  data() {
    return {
      isLoading: false,
      fields: [
        { key: "index", label: "" },
        { key: "receiptNo", label: "เลขที่ใบเสร็จ", sortable: true },
        { key: "creationDt", label: "วันที่ออกใบเสร็จ", sortable: true },
        { key: "dn", label: "DN./DN.(เดิม)", class: "narrow-spacing" },
        { key: "firstNameTh", label: "ชื่อคนไข้" },
        { key: "paid", label: "ชำระ(บาท)" },
        { key: "status", label: "" },
        { key: "button", label: "" },
      ],
      searchTable: null,
      listOffset: 0,
      listLimit: 20,
      dateRange: [],
      receiptStatus: null,
      receiptStatusOpt: [
        { value: null, text: "ทั้งหมด" },
        { value: "Success", text: "ปกติ" },
        { value: "Voided", text: "ยกเลิก" },
      ],
      isTableLoading: false,
      checkScrollTimeout: null,
    };
  },

  computed: {
    ...mapGetters({
      rawReceiptList: "moduleReceipt/getReceiptList",
      getReceiptTotalNo: "moduleReceipt/getReceiptTotalNo",
      isManager: "moduleUser/isManager",
      isHeadCounter: "moduleUser/isHeadCounter",
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
    }),

    receiptList() {
      return this.rawReceiptList.map(receipt => ({
        ...receipt,
        _rowVariant: receipt.cancel ? "secondary" : "",
      }));
    },

    startDt() {
      return this.dateRange[0]
        ? moment(this.dateRange[0]).startOf("day").format()
        : "";
    },

    endDt() {
      return this.dateRange[1]
        ? moment(this.dateRange[1]).endOf("day").format()
        : "";
    },

    today() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },

    sevenDaysAgo() {
      const date = new Date(this.today);
      date.setDate(date.getDate() - 7);
      return date;
    },

    isDateSelected() {
      return !this.startDt || !this.endDt;
    },

    searchParams() {
      return {
        search: this.searchTable?.trim() || "",
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        offset: this.listOffset,
        limit: this.listLimit,
        startDt: this.startDt || null,
        endDt: this.endDt || null,
        status: this.receiptStatus,
      };
    },
  },

  created() {
    this.debouncedSearch = debounce(this.searchReceipt, 300);
    this.debouncedHandleScroll = debounce(this.handleScroll, 300);
  },

  mounted() {
    window.addEventListener("scroll", this.debouncedHandleScroll);
    this.dateRange = [this.today, this.today];
    this.searchReceipt();
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.debouncedHandleScroll);
    if (this.checkScrollTimeout) {
      clearTimeout(this.checkScrollTimeout);
    }
  },

  methods: {
    ...mapActions({
      fetchPatientById: "modulePatient/fetchPatientById",
      fetchReceiptById: "moduleReceipt/fetchReceiptById",
      fetchReceiptList: "moduleReceipt/fetchReceiptList",
      fetchReceiptScrollList: "moduleReceipt/fetchReceiptScrollList",
    }),

    handleDateChange() {
      if (!this.isDateSelected) {
        this.searchReceipt();
      }
    },

    handleStatusChange() {
      this.searchReceipt();
    },

    async searchReceipt() {
      if (this.isDateSelected) return;

      this.isTableLoading = true;
      this.listOffset = 0;

      try {
        await this.fetchReceiptList(this.searchParams);
      } finally {
        this.isTableLoading = false;
        this.$nextTick(() => this.checkAndLoadMore());
      }
    },

    checkAndLoadMore() {
      const viewportHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (
        !this.isTableLoading &&
        this.getReceiptTotalNo > this.receiptList.length &&
        (documentHeight <= viewportHeight ||
          scrollTop + viewportHeight >= documentHeight - 100)
      ) {
        this.loadMoreData();
      }
    },

    async loadMoreData() {
      this.isTableLoading = true;
      this.listOffset += this.listLimit;

      try {
        await this.fetchReceiptScrollList(this.searchParams);
      } finally {
        this.isTableLoading = false;
        this.$nextTick(() => {
          if (document.documentElement.scrollHeight <= window.innerHeight) {
            this.checkAndLoadMore();
          }
        });
      }
    },

    handleScroll() {
      if (this.checkScrollTimeout) {
        clearTimeout(this.checkScrollTimeout);
      }
      this.checkScrollTimeout = setTimeout(() => {
        this.checkAndLoadMore();
      }, 200);
    },

    async openReceiptModal(data) {
      this.isLoading = true;

      try {
        const [receiptRes, patientRes] = await Promise.all([
          this.fetchReceiptById({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            id: data.id,
          }),
          this.fetchPatientById({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            id: data.patientId,
          }),
        ]);

        if (receiptRes.status === 200 && patientRes.status === 200) {
          if (receiptRes.data.id && patientRes.data.id) {
            this.$refs.paymentReceiptPreviewModal.show(
              receiptRes.data,
              patientRes.data,
              data.cancel ? "cancel" : "copy",
              this.isManager || this.isHeadCounter
            );
          } else {
            this.showError();
          }
        }
      } catch (err) {
        console.error("Error:", err);
        this.showError();
      } finally {
        this.isLoading = false;
      }
    },

    showError() {
      eventBus.$emit("alertSwal", {
        title: "ระบบขัดข้อง",
        message: "ไม่สามารถแสดงข้อมูลใบเสร็จได้",
        icon: "error",
      });
    },

    formatDate(date) {
      return date ? moment(date).locale("th").format("D MMM YY") : "";
    },

    formatTime(date) {
      return date ? moment(date).locale("th").format("HH:mm") : "";
    },

    cancelPopoverConfig(e) {
      return {
        html: true,
        variant: "danger",
        title: () => `ใบเสร็จ ${e.receiptNo} ถูกยกเลิก`,
        content: () => `
          โดย:<em> ${e.cancelFirstNameTh ?? ""} ${
          e.cancelLastNameTh ?? ""
        }</em><br>
          วันเวลา:<em> ${this.formatDate(e.cancelDt) ?? ""} ${
          this.formatTime(e.cancelDt) ?? ""
        }</em><br>
          สาเหตุ:<em> ${e.cancelRemark ?? ""}</em>
        `,
      };
    },

    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
};
</script>

<style scoped>
</style>